import {
  ElemasonComponentType,
  ElemasonConfig,
  ElemasonWidgetType,
} from '@tectonic/types';

const elemasonConfig: ElemasonConfig = {
  widgets: {
    [ElemasonWidgetType.ProductList]: {
      list: { gapX: '3' },
      sourceCard: {
        title: {},
        subtitle: {},
        container: {
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
        },
      },
      header: {
        title: { variant: 'body1', markdown: true },
        container: { paddingX: '4', paddingTop: '6', paddingBottom: '3' },
      },
      product: {
        size: 'sm',
        aspectRatio: 0.563,
      },
      cardWidths: {
        xs: 144,
        sm: 192,
        md: 234,
        lg: 270,
        xl: 360,
        '2xl': 432,
      },
    },
  },
  components: {
    [ElemasonComponentType.Toast]: {
      container: {
        paddingY: '3',
        paddingX: '4',
        marginBottom: '20',
        borderRadius: 'md',
        backgroundColor: 'black',
        justifyContent: 'center',
      },
      title: {
        color: 'white',
        variant: 'subtext4',
      },
      description: {
        color: 'white',
        variant: 'subtext5',
      },
    },
    [ElemasonComponentType.VariantSelector]: {
      size: {
        container: {
          gap: '1.5',
          width: '100%',
          overflow: 'auto',
          alignItems: 'center',
          borderRadius: 'full',
        },
        selectedItem: {
          paddingY: '1.5',
          paddingX: '2.5',
          borderRadius: 'full',
          backgroundColor: 'neutral-950',
        },
        item: {
          border: '1',
          paddingY: '1.5',
          paddingX: '2.5',
          borderRadius: 'full',
          alignItems: 'center',
          borderColor: 'neutral-950',
        },
        unavailableItem: {
          borderColor: 'neutral-400',
        },
        text: {
          color: 'neutral-950',
          variant: 'body4',
          fontWeight: 'semibold',
        },
        unavailableText: {
          color: 'neutral-400',
          variant: 'body4',
          fontWeight: 'semibold',
        },
        selectedText: {
          color: 'white',
          variant: 'body4',
          fontWeight: 'semibold',
        },
      },
      color: {
        container: {
          width: '100%',
          overflow: 'auto',
        },
        item: {
          alignItems: 'center',
        },
        unavailableIcon: 'outline-unavailable-color',
        unavailableIconConfig: {
          color: 'white',
          size: 'md',
        },
        selectedItem: {
          border: '1',
          alignItems: 'center',
          borderRadius: 'full',
          borderColor: 'primary-400',
          paddingEnd: '3',
        },
        selectedItemText: {
          variant: 'body4',
        },
        swatch: {
          itemContainer: {
            margin: '1',
          },
          item: {
            width: 24,
            height: 24,
          },
        },
      },
    },
    [ElemasonComponentType.Swatch]: {
      item: {
        width: 16,
        height: 16,
        overflow: 'hidden',
        borderRadius: 'full',
      },
      itemContainer: {
        border: '1',
        margin: '1',
        borderColor: 'white',
        borderRadius: 'full',
      },
    },
    // [ElemasonComponentType.ProductCard]: {
    //   container: {
    //     gap: '2',
    //     overflow: 'hidden',
    //     borderRadius: 'lg',
    //     backgroundColor: 'white',
    //   },
    //   media: {
    //     aspectRatio: 1,
    //     width: '100%',
    //   },
    //   details: {
    //     brand: {
    //       color: 'neutral-500',
    //       variant: 'body4',
    //       textTransform: 'uppercase',
    //     },
    //     description: {
    //       visible: false,
    //     },
    //     title: {
    //       height: 42,
    //       variant: 'body3',
    //       color: 'neutral-950',
    //       numberOfLines: 2,
    //     },
    //     container: {
    //       paddingX: '2',
    //       paddingBottom: '3',
    //     },
    //     pricing: {
    //       container: {
    //         gapX: '1.5',
    //         paddingTop: '3',
    //         alignItems: 'center',
    //       },
    //       price: {
    //         text: {
    //           color: 'primary-400',
    //           variant: 'body3',
    //           fontWeight: 'semibold',
    //         },
    //       },
    //       discount: {
    //         text: {
    //           variant: 'body4',
    //           color: 'success-600',
    //           fontWeight: 'semibold',
    //         },
    //       },
    //       mrp: {
    //         text: {
    //           variant: 'body4',
    //           color: 'neutral-500',
    //         },
    //       },
    //     },
    //     variants: {
    //       config: {
    //         size: {
    //           container: {
    //             gap: '1.5',
    //             paddingTop: '2',
    //           },
    //           // config: {
    //           //   variant: ProductVariantsSizeVariant.DROPDOWN,
    //           //   icon: 'outline-chevron-down',
    //           //   iconConfig: {
    //           //     size: 'sm',
    //           //   },
    //           //   text: {
    //           //     variant: 'body4',
    //           //     fontWeight: 'semibold',
    //           //   },
    //           //   container: {
    //           //     border: '1',
    //           //     paddingY: '1',
    //           //     paddingX: '2.5',
    //           //     width: 'fit-content',
    //           //     borderRadius: 'full',
    //           //     borderColor: 'primary-200',
    //           //   },
    //           // },
    //           config: {
    //             variant: ProductVariantsSizeVariant.PILLS,
    //             text: {
    //               variant: 'body4',
    //               fontWeight: 'semibold',
    //             },
    //             container: {
    //               border: '1',
    //               paddingY: '1',
    //               paddingX: '2.5',
    //               width: 'fit-content',
    //               alignItems: 'center',
    //               borderRadius: 'full',
    //               borderColor: 'primary-200',
    //               justifyContent: 'center',
    //             },
    //             moreContainer: {
    //               paddingY: '1',
    //               paddingX: '2.5',
    //               width: 'fit-content',
    //               alignItems: 'center',
    //               borderRadius: 'full',
    //               justifyContent: 'center',
    //               backgroundColor: 'neutral-50',
    //             },
    //           },
    //         },
    //         color: {
    //           container: {
    //             gap: '1.5',
    //             paddingTop: '2',
    //           },
    //           // config: {
    //           //   icon: 'outline-chevron-down',
    //           //   iconConfig: {
    //           //     size: 'sm',
    //           //   },
    //           //   variant: ProductVariantsColorVariant.DROPDOWN,
    //           //   container: {
    //           //     gap: '1',
    //           //     border: '1',
    //           //     width: '100%',
    //           //     borderRadius: 'full',
    //           //     borderColor: 'primary-200',
    //           //   },
    //           //   text: {
    //           //     variant: 'body4',
    //           //     fontWeight: 'semibold',
    //           //   },
    //           //   textContainer: {
    //           //     width: '100%',
    //           //     paddingEnd: '1.5',
    //           //     alignItems: 'center',
    //           //     justifyContent: 'center',
    //           //   },
    //           // },
    //           config: {
    //             variant: ProductVariantsColorVariant.SWATCH,
    //             maxColors: 5,
    //             container: {
    //               border: '1',
    //               borderRadius: 'full',
    //               borderColor: 'primary-200',
    //             },
    //             item: {
    //               width: 16,
    //               height: 16,
    //               overflow: 'hidden',
    //               borderRadius: 'full',
    //             },
    //             itemContainer: {
    //               border: '1',
    //               margin: '1',
    //               offsetLeft: -12,
    //               borderColor: 'white',
    //               borderRadius: 'full',
    //             },
    //             text: {
    //               variant: 'body4',
    //               fontWeight: 'semibold',
    //             },
    //             textContainer: {
    //               height: '100%',
    //               paddingEnd: '1.5',
    //               alignItems: 'center',
    //               justifyContent: 'center',
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    //   ratingsCta: {
    //     visible: true,
    //     data: {
    //       startIcon: 'solid-star',
    //       text: '{average}',
    //     },
    //     config: {
    //       gap: '0',
    //       size: 'xs',
    //       paddingY: '0',
    //       paddingX: '0.5',
    //       variant: 'solid',
    //       backgroundColor: 'neutral-50',
    //       text: { color: 'neutral-950' },
    //     },
    //     container: {
    //       start: 8,
    //       bottom: 8,
    //       overflow: 'hidden',
    //       position: 'absolute',
    //       borderRadius: 'full',
    //     },
    //   },
    //   cartCta: {
    //     visible: true,
    //     data: {
    //       startIcon: 'outline-add-to-cart',
    //     },
    //     config: {
    //       size: 'sm',
    //       padding: '1.5',
    //       variant: 'solid',
    //       text: { color: 'neutral-950' },
    //       backgroundColor: 'primary-50',
    //     },
    //     container: {
    //       end: 8,
    //       bottom: 8,
    //       overflow: 'hidden',
    //       position: 'absolute',
    //       borderRadius: 'full',
    //     },
    //   },
    //   wishlistCta: {
    //     visible: true,
    //     data: {
    //       wishlist: {
    //         startIcon: 'outline-wishlist',
    //       },
    //       wishlisted: {
    //         startIcon: 'outline-wishlisted',
    //       },
    //     },
    //     config: {
    //       size: 'md',
    //       padding: '1.5',
    //       variant: 'ghost',
    //       text: { color: 'neutral-950' },
    //       backgroundColor: 'white',
    //     },
    //     container: {
    //       end: 4,
    //       top: 4,
    //       overflow: 'hidden',
    //       position: 'absolute',
    //       borderRadius: 'full',
    //     },
    //   },
    // },
    [ElemasonComponentType.ProductPricing]: {
      container: {
        gapX: '1.5',
        paddingTop: '3',
        alignItems: 'center',
      },
      price: {
        text: {
          color: 'primary-400',
          variant: 'body3',
          fontWeight: 'semibold',
        },
      },
      discount: {
        text: {
          variant: 'body4',
          color: 'success-600',
          fontWeight: 'semibold',
        },
      },
      mrp: {
        text: {
          variant: 'body4',
          color: 'neutral-500',
        },
      },
    },
    // [ElemasonComponentType.ProductBadges]: {
    //   visible: true,
    //   data: {
    //     text: `{name}`,
    //   },
    //   config: {
    //     badgesToShow: 2,
    //     borderRadius: 'sm',
    //     width: 'fit-content',
    //     //color: 'white',
    //     paddingX: '2',
    //     paddingY: '0.5',
    //     variant: 'body6',
    //     fontWeight: 'semibold',
    //   },
    //   container: {
    //     paddingEnd: '2',
    //     top: 4,
    //     gap: '2',
    //     start: 0,
    //     overflow: 'hidden',
    //     position: 'absolute',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //   },
    //   icon: {
    //     size: 'sm',
    //     paddingEnd: '2',
    //   },
    // },
  },
} as any;

export { elemasonConfig };
